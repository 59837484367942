import React from "react"

import { Layout, Seo } from "../components"
import { Link } from "gatsby"

const NotFoundPage = () => {
  const [isMount, setMount] = React.useState(false)

  React.useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return null
  }

  return (
    <Layout>
      <Seo title="Strona nie znaleziona" />

      <div className="error-page-inner bg_color--4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h1 className="title theme-gradient">404!</h1>
                <h3 className="sub-title mb--0">
                  Strona nie została znaleziona
                </h3>
                <div className="error-button">
                  <Link className="rn-button-style--2 btn-solid" to="/">
                    Wróć na stronę główną
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
